<template>
  <div class="details-main">
    <div class="head">
      <!--      图、视频、gia-->
      <div class="show">
        <div class="show-main">
          <!--        图片-->
          <img class="show-img" v-show="swiperShow == 'img' && items.originalImage != ''" :src="items.originalImage" alt="">
          <!--        视频-->
          <iframe v-show="swiperShow == 'video' && items.video != ''" :src="items.video" frameborder="0"></iframe>
          <iframe v-if="Giaimg == '' && PDFshow" v-show="swiperShow == 'GIA' && items.certificate != '' && items.certificate != 'null'" :src="items.certificate" frameborder="0"></iframe>
          <!--        GIA-->
          <img class="gia-loading" v-if="!PDFshow" v-show="swiperShow == 'GIA' && Giaimg == '' && giaFalse" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/btnLoading.png" alt="">
          <img class="show-img GIA" v-if="!PDFshow" v-show="swiperShow == 'GIA' && Giaimg != ''" :src="Giaimg" alt="">

          <div class="new-cert-pass" v-show="swiperShow == 'GIA' && (items.certificate == '' || items.certificate == 'null')">
            <div class="new-cert-main">
              <div class="new-cert-head">{{newCertType == 3 ? '该证书PDF文件无法显示' : '该证书供应商未提供，无法正常显示。'}}</div>
              <div class="new-cert-body" v-show="newCertType == 1">
                <div class="new-items">
                  <div class="items">
                    <div class="item" :class="VipItem == 1 ? 'sel' : ''" @click="VipItem = 1">
                      <div class="text">VIP通道1</div>
                      <div class="price">免费</div>
                    </div>
                    <div class="item" :class="VipItem == 2 ? 'sel' : ''" @click="VipItem = 2">
                      <div class="text">VIP通道2</div>
                      <div class="price">免费</div>
                    </div>
                    <div class="item" :class="VipItem == 3 ? 'sel' : ''" @click="VipItem = 3">
                      <div class="text">VIP通道3</div>
                      <div class="price">免费</div>
                    </div>
                  </div>
                  <div class="new-btns" @click="getNewPDF">
                    获取证书
                  </div>
                </div>
              </div>

              <div class="new-cert-load" v-show="newCertType == 2">
                <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/details-loading.png" class="new-loadimg" mode="aspectFit" />
                <div class="text">正在获取中,请不要离开。</div>
              </div>

              <div class="new-cert-fail" v-show="newCertType == 3">
                <div class="new-cert-centent">
                  <div class="title">为什么会出现PDF文件无法显示的情况？</div>
                  <div class="new-cert-cunter">供应商填写的证书号错误。</div>
                  <div class="new-cert-cunter">证书是2010年7月份之前颁发的证书。</div>
                  <div class="new-cert-cunter">国际网络导致证书信息同步异常。</div>
                </div>
              </div>
            </div>
          </div>

          <div class="sw-getnew-main" v-show="swiperShow == 'GIA' && isGetNew">
            <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/details-loading.png" class="sw-g-img" mode="aspectFit" />
            <div class="text">正在同步证书信息，请不要离开。</div>
          </div>

          <div class="no-nothing" v-show="items.originalImage == '' && items.video == '' && Giaimg == '' && !giaFalse">
            <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/bgno.png" alt="">
            <div class="text">暂无图片哦！</div>
          </div>
        </div>

        <div class="items">
          <div class="item btn" v-show="items.originalImage != ''" :class="swiperShow == 'img' ? 'set' : ''"
               @click="ivgShow('img')">高清图
          </div>
          <div class="item btn" v-show="items.video != ''" :class="swiperShow == 'video' ? 'set' : ''"
               @click="ivgShow('video')">视频
          </div>
          <div class="item btn" :class="swiperShow == 'GIA' ? 'set' : ''" @click="ivgShow('GIA')">
            证书
          </div>
        </div>
      </div>
      <!--      价格详情-->
      <div class="details">
        <div class="title">{{items.ct}}{{items.shape}}切割钻石</div>
        <div class="price">
          <div class="label">价格</div>
          <div class="money"><span>￥</span>{{items.price || '-'}}</div>
        </div>
        <div class="cert">
          <div class="label">证书</div>
          <div class="num">
            <span>{{items.cert}}</span>
            {{items.certNo}}
            <span class="a_span" @click="GiaimgShow = true" v-show="Giaimg != ''">查看证书</span>
            <span class="a_span" @click="openPDF(items.certificate)" v-show="Giaimg == '' && items.certificate != '' && items.certificate != 'null'">查看证书</span>
          </div>
          <img v-show="items.cert == 'GIA'" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/pc_GIA.png" alt="">
        </div>
        <!-- <div class="btn" @click="checkQRcode" v-show="!getorderShow">立即下单</div> -->
        <!-- 13677166673 -->
        <div class="btn" @click="checkQRcode" v-show="false">立即下单</div>
        <div class="btn btn-link" @mouseenter="mouseQRcode" @mouseleave="leaveQRcode" v-show="getorderShow">
          立即下单
          <div class="show-diage" v-show="getorders">
            <div class="qrcode" ref="qrCodeUrls"></div>
            <div class="text_qr">扫码下单</div>
          </div>
        </div>
        <!--        <div class="remind">-->
        <!--          <div class="label">提醒：</div>-->
        <!--          <div class="text">今天下单——预计02月12日发货，如需定制款式，需增加额外时间</div>-->
        <!--        </div>-->
        <div class="protocol">除到货时发现双方承认的质量问题外，其他情况下定金不可退，也不可换购其他产品特别提示：到货周期会受到国家法定节假日影响而顺延；具体顺延时间请参考当期网站通告。</div>
      </div>
    </div>

    <!--    钻石图片,详情-->
    <div class="master">
      <div class="main">
        <div class="title">钻石详细资料</div>
        <div class="body">
          <div class="items" v-show="items.cert == 'GIA'">
            <div class="item title">{{items.cert}}检测报告</div>
            <div class="item"><div>证书编号</div><div>{{items.certNo || '-'}}</div></div>
            <div class="item"><div>颁发日期</div><div>{{items.awardTime || '-'}}</div></div>
            <div class="item title">Round Brilliant</div>
            <div class="item"><div>尺寸</div><div>{{items.measurement || '-'}}</div></div>
            <div class="item"><div>重量</div><div>{{items.ct || '-'}}</div></div>
            <div class="item"><div>颜色</div><div>{{items.color || '-'}}</div></div>
            <div class="item"><div>净度</div><div>{{items.clarity || '-'}}</div></div>
            <div class="item"><div>切工</div><div>{{items.cut || '-'}}</div></div>
            <div class="item title">Proportions</div>
            <div class="item"><div>全深比</div><div>{{items.fullDepth || '-'}}</div></div>
            <div class="item"><div>台宽比</div><div>{{items.diaTable || '-'}}</div></div>
            <div class="item"><div>冠角</div><div>{{items.crownAngle || '-'}}</div></div>
            <div class="item"><div>冠高比</div><div>{{items.crownHeight || '-'}}</div></div>
            <div class="item"><div>亭角</div><div>{{items.pavilionAngle || '-'}}</div></div>
            <div class="item"><div>亭深比</div><div>{{items.diaDepth || '-'}}</div></div>
            <div class="item"><div>星小面比</div><div>{{items.starFacet || '-'}}</div></div>
            <div class="item"><div>下腰小面比</div><div>{{items.lowerLumbarFacet || '-'}}</div></div>
            <div class="item"><div>腰棱</div><div>{{items.girdle || '-'}}</div></div>
            <div class="item"><div>底尖</div><div>{{items.culet || '-'}}</div></div>
            <div class="item title">Finish</div>
            <div class="item"><div>抛光</div><div>{{items.polish || '-'}}</div></div>
            <div class="item"><div>对称</div><div>{{items.sym || '-'}}</div></div>
            <div class="item title">Fluorescence</div>
            <div class="item"><div>荧光</div><div>{{items.flour || '-'}}</div></div>
            <div class="item title">Clarity CharacteristicsS</div>
            <div class="item"><div>净度特征</div><div class="clearity">{{items.chinese || '-'}}</div></div>
            <div class="item title">Inscription(s)</div>
            <div class="item"><div>腰码</div><div>{{items.inscription || '-'}}</div></div>
            <div class="item title">Comments</div>
            <div class="item"><div></div><div>{{items.comment || '-'}}</div></div>
          </div>

          <div class="items" v-show="items.cert == 'IGI'">
            <div class="item title">{{items.cert}}检测报告</div>
            <div class="item"><div>证书编号</div><div>{{items.certNo || '-'}}</div></div>
            <div class="item"><div>报告地和日期</div><div>{{items.igiReportCityDate || '-'}}</div></div>
            <div class="item"><div>描述</div><div>{{items.igiDescription || '-'}}</div></div>
            <div class="item"><div>形状和切工</div><div>{{items.igiShapeAndCut || '-'}}</div></div>
            <div class="item title">Grading Results</div>
            <div class="item"><div>重量</div><div>{{items.ct || '-'}}</div></div>
            <div class="item"><div>颜色</div><div>{{items.color || '-'}}</div></div>
            <div class="item"><div>净度</div><div>{{items.clarity || '-'}}</div></div>
            <div class="item"><div>切工</div><div>{{items.cut || '-'}}</div></div>
            <div class="item title">Additional Grading Information</div>
            <div class="item"><div>抛光</div><div>{{items.polish || '-'}}</div></div>
            <div class="item"><div>对称</div><div>{{items.sym || '-'}}</div></div>
            <div class="item"><div>尺寸</div><div>{{items.measurement || '-'}}</div></div>
            <div class="item"><div>台宽比</div><div>{{items.diaTable || '-'}}</div></div>
            <div class="item"><div>冠高比</div><div>{{items.crownHeight || '-'}}</div></div>
            <div class="item"><div>亭深比</div><div>{{items.diaDepth || '-'}}</div></div>
            <div class="item"><div>腰厚</div><div>{{items.waistThickness || '-'}}</div></div>
            <div class="item"><div>底尖</div><div>{{items.culet || '-'}}</div></div>
            <div class="item"><div>全深比</div><div>{{items.fullDepth || '-'}}</div></div>
            <div class="item"><div>荧光</div><div>{{items.flour || '-'}}</div></div>
            <div class="item"><div>腰码</div><div>{{items.inscription || '-'}}</div></div>
          </div>
          <div class="items" v-show="items.cert == 'HRD'">
            <div class="item title">{{items.cert}}检测报告</div>
            <div class="item"><div>证书编号</div><div>{{items.certNo || '-'}}</div></div>
            <div class="item"><div>证书类型</div><div>{{items.reportType || '-'}}</div></div>
            <div class="item"><div>颁发日期</div><div>{{items.awardTime || '-'}}</div></div>
            <div class="item title">Results</div>
            <div class="item"><div>形状</div><div>{{items.shape || '-'}}</div></div>
            <div class="item"><div>重量</div><div>{{items.ct || '-'}}</div></div>
            <div class="item"><div>颜色</div><div>{{items.color || '-'}}</div></div>
            <div class="item"><div>净度</div><div>{{items.clarity || '-'}}</div></div>
            <div class="item"><div>切工</div><div>{{items.cut || '-'}}</div></div>
            <div class="item"><div>比例</div><div>{{items.proportions || '-'}}</div></div>
            <div class="item"><div>抛光</div><div>{{items.polish || '-'}}</div></div>
            <div class="item title">Additional Information</div>
            <div class="item"><div>荧光</div><div>{{items.flour || '-'}}</div></div>
            <div class="item"><div>尺寸</div><div>{{items.measurement || '-'}}</div></div>
            <div class="item"><div>腰棱</div><div>{{items.girdle || '-'}}</div></div>
            <div class="item"><div>底尖</div><div>{{items.culet || '-'}}</div></div>
            <div class="item"><div>全深比</div><div>{{items.fullDepth || '-'}}</div></div>
            <div class="item"><div>台宽比</div><div>{{items.diaTable || '-'}}</div></div>
            <div class="item"><div>冠高比</div><div>{{items.crownHeight || '-'}}</div></div>
            <div class="item"><div>亭深比</div><div>{{items.diaDepth || '-'}}</div></div>
            <div class="item"><div>冠部上腰面水平投影长度比</div><div>{{items.halvesCrown || '-'}}</div></div>
            <div class="item"><div>亭部下腰面水平投影长度比</div><div>{{items.halvesPavilion || '-'}}</div></div>
            <div class="item"><div>α & β之和</div><div>{{items.hrdSum || '-'}}</div></div>
            <div class="item"><div>腰码</div><div>{{items.inscription || '-'}}</div></div>
            <div class="item"><div>备注</div><div>{{items.comment || '-'}}</div></div>
          </div>
          <!--          详情右侧图解-->
          <div class="position">
            <div class="map" :class="items.imgType" v-show="imgType == items.imgType">
              <div class="diaTable">台宽比 <span>{{items.diaTable || '--'}}</span></div>
              <div class="crownHeight" v-show="imgType == 'ROUND'">冠高比 <span>{{items.crownHeight || '--'}}</span></div>
              <div class="diaDepth" v-show="imgType == 'ROUND'">亭深比 <span>{{items.diaDepth || '--'}}</span></div>
              <div class="fullDepth">全深比 <p>{{items.fullDepth || '--'}}</p></div>
              <div class="waistThickness" v-show="imgType == 'ROUND'">腰厚比 <p>{{items.waistThickness || '--'}}</p></div>
              <div class="m1">直径 <span>{{items.m1 ? items.m1+'mm' : '--'}}</span></div>
              <div class="m2">直径 <span>{{items.m2 ? items.m2+'mm' : '--'}}</span></div>
              <div class="m3" v-show="imgType == 'ROUND'">高 <span>{{items.m3 ? items.m3+'mm' : '--'}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    canvas、介绍-->
    <div class="specif main">
      <div class="items" :style="{background: colorData.bgColor,color: colorData.fontcolor}">
        <div class="title">{{colorData.title}}</div>
        <div class="text">{{colorData.text}}</div>
        <div class="specif-list">
          <div class="item" :class="ite.check ? 'check':''" v-for="(ite, inde) in colorData.child" :key="inde">{{ite.val}}</div>
        </div>
      </div>
      <div class="items" :style="{background: clarityData.bgColor,color: clarityData.fontcolor}">
        <div class="title">{{clarityData.title}}</div>
        <div class="text">{{clarityData.text}}</div>
        <div class="specif-list">
          <div class="item" :class="ite.check ? 'check':''" v-for="(ite, inde) in clarityData.child" :key="inde">{{ite.val}}</div>
        </div>
      </div>
      <div class="items" :style="{background: cutData.bgColor,color: cutData.fontcolor}" v-show="cutData.shape == '圆形'">
        <div class="title">{{cutData.title}}</div>
        <div class="text">{{cutData.text}}</div>
        <div class="specif-list">
          <div class="item" :class="ite.check ? 'check':''" v-for="(ite, inde) in cutData.child" :key="inde">{{ite.val}}</div>
        </div>
      </div>
      <div class="items canvas">
        <div class="title">综合分值</div>
        <div class="canvas">
          <Progress :percentage="percentage"></Progress>
          <div class="progress-main">
            <div class="text">{{items.totalScore}}<span>分</span></div>
            <div class="space">品质很好</div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-bg" v-show="getorder" @click="downPopup"></div>

    <div class="popup-main" v-show="getorder">
      <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/cutdown.png" alt="" class="cut-down" @click="downPopup">
      <div class="border-main">
        <img class="qr" src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/qrcode.png" alt="">
        <div class="qrcode" ref="qrCodeUrl"></div>
      </div>
    </div>

    <div class="popup-bgs" v-show="GiaimgShow">
      <div class="ngss" @click.self="GiaimgShow = false">
        <img :src="Giaimg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  import Diamond from '@/api/diamond'
  import Progress from '@/components/progress/vue_progress'
  import newRequest from '@/api/request'
  import QRCode from 'qrcodejs2'

  export default {
    name: 'diamondDetails',
    data () {
      return {
        shop:'',
        // 进度条参数
        percentage: 90,
        // 钻石id
        GoodsBarCode: '',
        // 店铺id
        relationId: '',
        // 详情
        specific: [],
        items: '',
        // 大图、视频、GIA
        swiperShow: '',
        Giaimg: '',
        imgType: '',
        getorder: false,
        getorders: false,
        getorderShow: false,
        relationIds: '',
        giaFalse: true,
        // canvas同级展示
        colorData: [],
        clarityData: [],
        cutData: [],
        // 手机打开pc，显示
        PDFshow: false,
        GiaList: [],
        GiaimgShow: false,
        // 同步接口
        isNewCert: false,
        VipItem: 1,
        newLoad: true,
        newCertType: 1, // 1 vip通道展示 2 加载中 3 加载失败
        isGetNew: false,
        giaPdf: false
      }
    },
    created () {
      if (this.$route.query.link) {
        this.$emit('showheader', false)
        this.$emit('showfooter', false)
        this.getorderShow = true
      }
      // console.log(JSON.parse(this.$route.query.fireCert));
    },
    components: {
      Progress
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationIds != '' || newValue == '') return
          this.relationIds = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            this.shop = shop
            this.$getif(shop.vipType)
            // this.getInit()
          }
        },
        immediate: true,
      }
    },
    mounted () {
      this.GoodsBarCode = this.$route.query.GoodsBarCode
      this.relationId = this.$route.query.relationId
      console.log(this.GoodsBarCode, this.relationId)
      this.getInit()
      this.getCode()
    },
    methods: {
      downPopup() {
        this.getorder = false
      },
      checkQRcode() {
        this.getorder = true
      },
      mouseQRcode() {
        this.getorders = true
      },
      leaveQRcode() {
        this.getorders = false
      },
      getCode() {
        // 获取地址
        let url = window.location.protocol + '//' + window.location.host
        // 替换字符
        // let str = 'http://web18857879332.weboooooo.com'
        // let mobile = 'http://18857879332.weboooooo.com'
        // url = url.replace(/\/\/web/, '//')
        url = url.replace(/palmes.shop/, "pz-usa.cn")
        // let str = 'http://15103523218.jzker.cn'
        // let mobile = 'http://15103523218.gil-usa.com'
        let pass = window.localStorage.getItem('searchPass') || ''
        console.log(pass);
        let uri = `${url}/#/pages/diamond/details?GoodsBarCode=${this.GoodsBarCode}&relationId=${this.relationId}&passShow=${pass}`
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: uri, // 需要转换为二维码的内容
          width: 260,
          height: 260,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
        var qrcode = new QRCode(this.$refs.qrCodeUrls, {
          text: uri, // 需要转换为二维码的内容
          width: 190,
          height: 190,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      },
      // 初始化
      getInit () {
        let params = {
          relationId: this.relationId,
          GoodsBarCode: this.GoodsBarCode
        }
        Diamond.detail(params).then(res => {
          console.log(res)
          if (res.status == 200) {
            let data = res.result

            if(!this.isNewCert) {
              if ((data.fullDepth == '' || data.fullDepth == '-') && (data.cert == 'GIA' || data.cert == 'HRD' || data.cert == 'IGI')) {
                this.getNewCert(this.relationId, data.certNo)
                this.isGetNew = true
              }else{
                this.isNewCert = true
              }
            }

            this.colorData = {
              shape: data.shape,
              title: `颜色${data.color}`,
              text: data.colorDescribe,
              bgColor: '#000000',
              fontcolor: '#ffffff',
              child: this.overData(['D/E/F', 'G/H/I/J', 'K/L/M', 'N-O/P-R'], data.color)
            }
            this.clarityData = {
              shape: data.shape,
              title: `净度${data.clarity}`,
              text: data.clarityDescribe,
              bgColor: '#B2B2B2',
              fontcolor: '#ffffff',
              child: this.overData(['FL/IF', 'VVS1/VVS2', 'VS1/VS2', 'SI1/SI2', 'I1/I2/I3'], data.clarity)
            }
            this.cutData = {
              shape: data.shape,
              title: `切工${data.cut || '-'}`,
              text: data.cutDescribe,
              bgColor: '#F0F0F0',
              fontcolor: '#333333',
              child: this.overData(['IDEAL', 'EX', 'VG', 'GD', 'FR/PR/PD'], data.cut)
            }

            // 右侧图解字符串切割
            let mdata = data.measurement.replace(/\s+/g,"");
            console.log(mdata)
            let marr = mdata.split('X')
            console.log(marr)
            if (marr.length == 3) {
              data.m1 = marr[0]
              data.m2 = marr[1]
              data.m3 = marr[2]
            }
            if (marr.length == 2) {
              data.m1 = marr[0]
              data.m2 = marr[1]
            }
            if (marr.length == 0) {
              data.m1 = marr[0]
              data.m2 = marr[1]
              data.m3 = marr[2]
            }

            var os = function() {
              var ua = navigator.userAgent,
                isWindowsPhone = /(?:Windows Phone)/.test(ua),
                isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
                isAndroid = /(?:Android)/.test(ua),
                isFireFox = /(?:Firefox)/.test(ua),
                isChrome = /(?:Chrome|CriOS)/.test(ua),
                isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(
                  ua)),
                isPhone = /(?:iPhone)/.test(ua) && !isTablet,
                isPc = !isPhone && !isAndroid && !isSymbian;

              return {
                isTablet: isTablet,
                isPhone: isPhone,
                isAndroid: isAndroid,
                isPc: isPc
              };
            }();

            // 如果是安卓，做转图片处理
            if (os.isAndroid || os.isTablet) {
              console.log('安卓,平板')
              this.PDFshow = false

              // GIA、IGI、HRD服务器PDF转图片
              if (data.certificate != '' || data.certificate == 'null') {
                this.getGIA(data.cert, data.certNo, data.certificate)
              } else {
                this.Giaimg = ''
                this.giaFalse = false
              }
            }else{
              console.log('is Not pc and is not ipone')
              this.PDFshow = true
            }

            // 如果是苹果，和pc直接pdf格式显示
            if (os.isPhone || os.isPc) {
            }

            if (data.cert == "GIA" && data.clarityCharacteristics != '') {
              data.chinese = this.$getChinese(data.clarityCharacteristics)
            }

            // 显示
            let val = ''
            switch (data.shape) {
              case '圆形':
                val = 'ROUND'
                break
              case '心形':
                val = 'HEART'
                break
              case '垫形':
                val = 'CUSHION'
                break
              case '公主方':
                val = 'PRINCESS'
                break
              case '梨形':
                val = 'PEAR'
                break
              case '椭圆形':
                val = 'OVAL'
                break
              case '祖母绿':
                val = 'EMERALD'
                break
              case '三角形':
                val = 'TRIANGLE'
                break
              case '雷蒂恩':
                val = 'RADIANT'
                break
              case '马眼':
                val = 'MARQUISE'
                break
              case '阿斯切':
                val = 'SQUARE'
                break
              case '其他':
                val = 'OTHER'
                break
            }
            data.imgType = val
            this.imgType = val

            // 头部克重截取
            let str = data.title[0].split(' ')[0]
            console.log(str)
            data.ct = str
            document.title = data.ct + data.shape +'切割钻石'
            this.sheItems(data)
            console.log(data)
            this.percentage = data.totalScore
            // data.specific = specific
            this.items = data
          }
        })
      },
      getGIA (cert, certNo, certificate) {
        if (certNo == '') return
        let id = ''
        if (cert == 'GIA') {
          id = certNo
        } else if (cert == "IGI") {
          id = certNo + '_IGI'
        } else if (cert == 'HRD'){
          id = certNo + '_HRD'
        }
        let params = {
          reportNo: id,
          uri: certificate
        }
        Diamond.PreviewPng(params).then(res => {
          console.log(res)
          this.giaFalse = false
          if (res.status == 200) {
            if (res.result != '') {
              this.Giaimg = res.result
              this.GiaList.push(res.result)
            } else {
              this.Giaimg = ''
            }
          } else {
            this.Giaimg = ''
          }
        }).catch(error => {
          this.giaFalse = false
        })
      },
      getNewPDF() {
        this.newCertType = 2;
        let accountId = this.shop.accountId
        let url = newRequest.GetCertFile;
        let params = {
          certNo: this.items.certNo,
          accountId: accountId
        }

        this.$http.get(url, {params}).then(res => {
          console.log(res)
          if (res.data.Code == 1) {
            if (res.data.Result.UpdateCertFileSuccessState == 1) {
              this.giaPdf = true

              var os = function() {
                var ua = navigator.userAgent,
                  isWindowsPhone = /(?:Windows Phone)/.test(ua),
                  isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
                  isAndroid = /(?:Android)/.test(ua),
                  isFireFox = /(?:Firefox)/.test(ua),
                  isChrome = /(?:Chrome|CriOS)/.test(ua),
                  isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(
                    ua)),
                  isPhone = /(?:iPhone)/.test(ua) && !isTablet,
                  isPc = !isPhone && !isAndroid && !isSymbian;

                return {
                  isTablet: isTablet,
                  isPhone: isPhone,
                  isAndroid: isAndroid,
                  isPc: isPc
                };
              }();

              // 如果是安卓，做转图片处理
              if (os.isAndroid || os.isTablet) {
                console.log('安卓,平板')
                this.PDFshow = false
                // GIA、IGI、HRD服务器PDF转图片
                this.getGIA(this.items.cert, this.items.certNo, res.data.Result.CertFile)
              }else{
                console.log('is Not pc and is not ipone')
                this.PDFshow = true
              }
              this.items.certificate = res.data.Result.CertFile
            } else {
              this.giaPdf = false
              this.Giaimg = ''
              this.newCertType = 3;
            }
          } else {
            this.giaPdf = false
            this.Giaimg = ''
            this.newCertType = 3;
          }
        }).catch(err => {
          console.log(err)
          this.giaPdf = false
          this.Giaimg = ''
          this.newCertType = 3;
        })
      },
      // 新同步接口
      getNewCert(relationId, certNo) {
        let accountId = this.shop.accountId
        let url = newRequest.GetCertInformationAsync
        let params = {
          certNo: certNo,
          accountId: accountId
        }

        this.$http.get(url, {params}).then(res => {
          if (res.data.Code == 1) {
            this.isNewCert = true
            this.isGetNew = false
            this.getInit(this.id, this.relationId)
          }else{
            this.isGetNew = false
          }
        }).catch(err => {
          console.log(err)
          this.isGetNew = false
        })
      },
      sheItems (data) {
        if (data.video != '') {
          this.swiperShow = 'video'
        }else if(data.originalImage != '') {
          this.swiperShow = 'img'
        }else{
          this.swiperShow = 'GIA'
        }
      },
      overData (arr, val) {
        // if (val == '') return
        const array = arr.map((item, index) => {
          let obj = {}
          let ischeck = item.split('/').includes(val)
          obj.val = item
          obj.check = ischeck
          return obj
        })
        return array
      },
      ivgShow (type) {
        this.swiperShow = type
      },
      openPDF(url) {
        console.log(url)
        if (this.PDFshow) {
          window.open(url)
        }else{
          window.open(this.Giaimg)
        }
      }
    },
    beforeRouteLeave (to, from, next) {
      if (to.path == '/diamondSearch') {
        to.meta.keepAlive = true
      } else {
        to.meta.keepAlive = false
      }
      next()
    },
  }
</script>

<style scoped lang="scss">
  @mixin dis-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .details-main {
    width: 100%;
    padding: 0 0 80px;

    .head {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 40px 0 80px;

      .show {
        margin-right: 154px;

        .show-img {
          width: 500px;
          height: 500px;

          &.GIA {
            width: 100%;
            height: auto;
          }
        }

        .gia-loading {
          width: 60px;
          height: 60px;
          animation: myRotate 1.9s linear infinite;
        }

        .show-main {
          width: 500px;
          height: 500px;
          /*background: url("../../assets/er.jpg");*/
          background-repeat: no-repeat;
          background-size: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .sw-getnew-main{
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #FFFFFF;

            .sw-g-img{
              width: 19px;
              height: 19px;
              margin-right: 5px;
              animation: myRotate .8s linear infinite;
            }

            .text{
              font-size: 15px;
              color: #333333;
            }
          }


          .new-cert-pass{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 99;

            .new-cert-main{
              width: 400px;
              height: 352px;
              background-color: #FFFFFF;
              z-index: 100;

              .new-cert-head{
                display: flex;
                justify-content: center;
                align-items: center;
                color: #E5C17C;
                font-size: 16px;
                width: 100%;
                height: 108px;
                background-image: url(https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/new-cert-bg.png);
                background-color: #585b5b;
                background-size: 100%;
                background-repeat: no-repeat;
              }

              .new-cert-body{
                height: 244px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .new-items{
                  width: 100%;
                  padding: 26px 14px 13px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: space-between;

                  .items{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .item{
                      width: 112px;
                      height: 131px;
                      background: #FFFFFF;
                      border-radius: 5px;
                      border: 1px solid #E5E5E5;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;

                      .text{
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 13px;
                      }

                      .price{
                        color: #EE0A24;
                        font-size: 15px;
                      }

                      &.sel{
                        background: #FCF0DD;
                        border: 2px solid #E5C17C;
                      }
                    }
                  }

                  .new-btns{
                    width: 100%;
                    height: 40px;
                    margin-top: 20px;
                    color: #E5C17C;
                    font-size: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-image: url(https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/new-cert-btnbg.png);
                    background-color: #585b5b;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    cursor: pointer;
                  }
                }
              }

              .new-cert-load{
                height: 205px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .new-loadimg{
                  width: 19px;
                  height: 19px;
                  animation: myRotate .9s linear infinite;
                  margin-right: 7px;
                }

                .text{
                  font-size: 15px;
                  color: #333333;
                }
              }

              .new-cert-fail{
                height: 205px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 35px 30px;

                .new-cert-centent{
                  width: 100%;

                  .title{
                    color: #1A1A1A;
                    font-size: 15px;
                    margin-bottom: 15px;
                  }

                  .new-cert-cunter{
                    color: #4C4C4C;
                    font-size: 14px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 9px;

                    &::before{
                      content: '';
                      display: inline-block;
                      width: 4px;
                      height: 4px;
                      background-color: #4C4C4C;
                      border-radius: 4px;
                      margin-right: 8px;
                    }
                  }
                }
              }
            }
          }
        }

        iframe {
          width: 500px;
          height: 500px;
        }

        .items {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .item {
            width: 65px;
            height: 65px;
            border: 1px solid #D9D9D9;
            line-height: 65px;
            text-align: center;
            font-size: 14px;
            color: #333333;
            margin: 0 7.5px;

            &.set {
              border-color: #FF8178;
            }
          }
        }
      }

      .details {
        width: 100%;
        padding: 20px 0;

        .title {
          font-size: 24px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 27px;
          font-family: SimSun;
        }

        .label {
          width: 50px;
          font-size: 14px;
          color: #999999;
        }

        .price {
          @include dis-start;
          align-items: center;

          .money {
            font-size: 24px;
            font-weight: 600;
            color: #FF8178;

            span {
              font-size: 17px;
            }
          }
        }

        .cert {
          @include dis-start;
          font-size: 14px;
          color: #666666;
          margin: 40px 0 47px;
          border-top: 1px solid #F0F0F0;
          border-bottom: 1px solid #F0F0F0;
          height: 64px;

          .num {
            span {
              font-size: 14px;
              margin-right: 5px;
            }

            .a_span {
              color: #FF8178;
              margin-left: 10px;
            }
          }

          img {
            margin-left: auto;
            width: 133px;
            height: 50px;
          }
        }

        .btn {
          width: 240px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          font-size: 14px;
          color: #FFFFFF;
          background: #FF8178;

          &.btn-link{
            position: relative;

            .show-diage{
              width: 222px;
              height: 240px;
              position: absolute;
              padding: 15px 15px 0px 15px;
              top: -260px;
              left: 0;
              background: #fff;
              border-radius: 0px;
              border: 1px solid #ebeef5;
              box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
              word-break: break-all;

              &::after{
                content: '';
                width: 0px;
                height: 0px;
                border-width: 6px;
                border-color: transparent;
                border-style: solid;
                border-top-color: #fff;
                position: absolute;
                bottom: -12px;
                left: 50%;
              }

              .text_qr{
                width: 100%;
                text-align: center;
                font-size: 14px;
                color: #000;
                line-height: 35px;
                height: 35px;
              }
            }
          }
        }

        .remind {
          margin-top: 30px;
          @include dis-start;

          .text {
            color: #FF8178;
            font-size: 14px;
          }
        }

        .protocol {
          width: 474px;
          color: #808080;
          font-size: 14px;
          margin-top: 15px;
        }
      }
    }

    .master {
      width: 100%;
      padding: 68px 0 80px;
      background: #F2F2F2;

      .title {
        font-size: 25px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 38px;
      }

      .body {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .items {
          .item {
            width: 600px;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            background: #FBECE0;
            padding: 0 20px;

            &.title {
              margin: 0;
              font-size: 15px;
            }

            &:nth-child(even) {
              background: #FFFFFF;
            }
          }
        }

        .position {
          width: 425px;
          height: 490px;
          margin-left: auto;

          .ROUND {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/ROUND.png');

            .diaTable {
              top: 32px;
              left: 77px;
            }

            .crownHeight {
              top: 82px;
              left: 215px;
            }

            .diaDepth {
              top: 148px;
              left: 214px;
            }

            .fullDepth {
              top: 121px;
              right: 63px;
            }

            .waistThickness {
              max-width: 80px;
              word-wrap: break-word;
              word-break: break-all;
              overflow: hidden;
              text-align: right;
              top: 142px;
              left: -60px;
            }

            .m1 {
              top: 233px;
              left: 74px;
            }

            .m2 {
              top: 350px;
              left: 225px;
            }

            .m3 {
              top: 353px;
              left: 80px;
            }
          }

          .HEART {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/HEART.jpg');

            .diaTable {
              top: 32px;
              left: 70px;
            }

            .fullDepth {
              top: 121px;
              right: 63px;
            }

            .m1 {
              top: 233px;
              left: 74px;
            }

            .m2 {
              top: 350px;
              left: 225px;
            }
          }

          .CUSHION {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/CUSHION.jpg');

            .diaTable {
              top: 32px;
              left: 55px;
            }

            .fullDepth {
              top: 121px;
              right: 63px;
            }

            .m1 {
              top: 233px;
              left: 63px;
            }

            .m2 {
              top: 350px;
              left: 225px;
            }
          }

          .PRINCESS {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/PRINCESS.jpg');

            .diaTable {
              top: 32px;
              left: 45px;
            }

            .fullDepth {
              top: 95px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 57px;
            }

            .m2 {
              top: 350px;
              left: 225px;
            }
          }

          .PEAR {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/PEAR.jpg');

            .diaTable {
              top: 32px;
              left: 100px;
            }

            .fullDepth {
              top: 110px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 130px;
            }

            .m2 {
              top: 350px;
              left: 240px;
            }
          }

          .OVAL {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/OVAL.jpg');

            .diaTable {
              top: 32px;
              left: 90px;
            }

            .fullDepth {
              top: 110px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 100px;
            }

            .m2 {
              top: 350px;
              left: 210px;
            }
          }

          .EMERALD {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/EMERALD.jpg');

            .diaTable {
              top: 32px;
              left: 60px;
            }

            .fullDepth {
              top: 95px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 70px;
            }

            .m2 {
              top: 350px;
              left: 190px;
            }
          }

          .RADIANT {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/RADIANT.jpg');

            .diaTable {
              top: 32px;
              left: 55px;
            }

            .fullDepth {
              top: 110px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 65px;
            }

            .m2 {
              top: 350px;
              left: 190px;
            }
          }

          .RADIANT {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/RADIANT.jpg');

            .diaTable {
              top: 32px;
              left: 55px;
            }

            .fullDepth {
              top: 110px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 65px;
            }

            .m2 {
              top: 350px;
              left: 190px;
            }
          }

          .MARQUISE {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/MARQUISE.jpg');

            .diaTable {
              top: 32px;
              left: 90px;
            }

            .fullDepth {
              top: 100px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 100px;
            }

            .m2 {
              top: 350px;
              left: 200px;
            }
          }

          .SQUARE {
            background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/details/SQUARE.png');

            .diaTable {
              top: 32px;
              left: 60px;
            }

            .fullDepth {
              top: 100px;
              right: 80px;
            }

            .m1 {
              top: 233px;
              left: 60px;
            }

            .m2 {
              top: 350px;
              left: 200px;
            }
          }

          .map {
            width: 100%;
            height: 100%;
            position: relative;
            background-size: 100%;
            background-repeat: no-repeat;

            div {
              font-size: 12px;
              color: #999999;
              position: absolute;

              span {
                font-size: 12px;
                color: #333333;
              }

              p {
                max-width: 80px;
                margin: 0;
                font-size: 12px;
                color: #333333;
              }
            }
          }
        }
      }
    }

    .specif {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 80px 0 0;

      .items {
        width: 289px;
        padding: 39px 20px;
        min-height: 395px;

        .title {
          font-size: 17px;
          font-weight: 600;
          margin-bottom: 14px;
        }

        .text {
          font-size: 13px;
          margin-bottom: 22px;
          min-height: 60px;
        }

        .item {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid #E5E5E5;
          background: #FFFFFF;
          color: #333333;
          text-align: center;

          &.check {
            background: #F5B5A9;
            border-color: #E5E5E5;
            color: #FFFFFF;
          }
        }

        &.canvas {
          border: 0px solid #FFFFFF;
          background: url('https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/pc_canbg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;

          .title {
            margin-bottom: 67px;
          }

          .canvas {
            width: 160px;
            height: 160px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .progress-main {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              align-content: center;

              .text {
                margin: 0;
                font-size: 35px;
                font-weight: 600;

                span {
                  font-size: 16px;
                }
              }

              .space {
                font-size: 13px;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }

  .popup-bg{
    /*width: 100vw;*/
    /*height: 100vh;*/
    background: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200000;
  }

  .popup-main{
    width: 400px;
    height: 400px;
    position: fixed;
    top: calc(50vh - 200px);
    left: calc(50vw - 200px);
    background: #FFF;
    z-index: 200001;

    .cut-down{
      position: absolute;
      top: -50px;
      right: 0px;
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    .border-main{
      padding: 16px;
      width: 100%;
      height: 100%;
      position: relative;

      &::before{
        content: '';
        width: 260px;
        height: 1px;
        position: absolute;
        top: 16px;
        left: calc(50% - 130px);
        background: #FFF;
      }

      .qr{
        width: 220px;
        height: 77px;
        position: absolute;
        top: -38px;
        left: calc(50% - 110px);
      }

      .qrcode{
        width: 100%;
        height: 100%;
        border: 1px solid #FF8178;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        padding: 49px;

        img{
          width: 270px;
          height: 270px;
        }
      }
    }
  }

  .no-nothing{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img{
      width: 200px;
      height: 200px;
      margin-top: -130px;
    }

    .text{
      font-size: 16px;
      color: #999999;
      margin-top: 20px;
    }
  }

  .popup-bgs{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200000;

    .ngss{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.5);
      display: flex;
      justify-content: center;
      align-items: center;

      img{
        transform: scale(1) rotate(0deg);
        margin-left: 0px;
        margin-top: 0px;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  @keyframes myRotate {
    0% {
      -webkit-transform: rotate(0deg);
    }

    50% {
      -webkit-transform: rotate(180deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }
</style>
